import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil, timeout } from "rxjs/operators";
import { ForecastHistory } from "../model/forecast-history.interface";

@Injectable()
export class HistoryService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  history$: ReplaySubject<ForecastHistory[]> = new ReplaySubject(1);

  constructor(private httpService: HttpClient,
    private configService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService,
    private notificationService: BfcNotificationService) {
  }

  ngOnDestroy(): void {
    this.history$.next(null);
    this.history$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadHistory(commonOrganisationId: string) {
    if (commonOrganisationId) {
      const url = this.configService.configuration.forecastApiUrl + "/forecastHistory/";
      const params = new HttpParams().set("companyForeignId", commonOrganisationId);

      this.httpService.get<ForecastHistory[]>(url, { params }).pipe(
        timeout(60000),
        takeUntil(this.unsubscribe),
      ).subscribe(data => {
        this.history$.next(data);
      },
      () => {
        this.notificationService.showNotification({
          type: BfcNotificationType.ERROR,
          message: this.bfcTranslationService.translate("FORECAST.ERROR.LOAD_HISTORY_FAILED"),
        });
      },
      );
    }
  }
}
