export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Prévision énergie",
  "FORECAST": {
    "COCKPIT": {
      "TITLE": "Prévision",
      "LINK_HISTORY": "Historique des prévisions",
      "TABLE": {
        "METERPOINT": "point de mesure",
        "TYPE": "Type",
        "LAST_UPDATE": "Dernière actualisation",
        "GATECLOSURE": "Date de soumission",
        "GATECLOSURE_FOR": "A soumettre pour le *",
        "HINT": "* La prévision concernant les jours de la semaine indiqués doit être transmise avant la prochaine date de soumission",
      },
    },
    "HISTORY": {
      "LINK_BACK": "« Retour à l'aperçu",
      "TITLE": "Historique des prévisions",
      "TABLE": {
        "DATE": "Horodatage",
        "BY": "Exécuté par",
        "NAME": "Nom du point de mesure",
        "CODE": "N° du point de mesure",
      },
    },
    "MANAGE": {
      "TITLE": "Prévision",
      "METERPOINT_SELECTION": "Sélectionner le point de mesure",
      "LOAD_FULLRANGE": "Charger la série chronologique complète",
      "WHICH_FORECAST": "Pour quelle prévision?",
      "TIMERANGE_TITLE": "Définir l'intervalle de temps",
      "TIMERANGE_START": "De (inclusif)",
      "TIMERANGE_END": "A (exclusif)",
      "TIMERANGE_ERROR": "La date de fin doit être postérieure à la date de début",
      "EDIT_PROVIDED": "Modifier la prévision BKW en ligne",
      "EDIT_IMPORTED": "Modifier la prévision importée en ligne",
      "STEPPER": {
        "TITLE": "Modifier la prévision",
        "BUTTON_CHANGE": "Modifier",
        "BUTTON_CONFIRM": "Confirmer",
        "BUTTON_NEXT": "Suivant",
        "BUTTON_PREVIOUS": "Précédent",
        "BUTTON_SUBMIT": "Valider",
        "BUTTON_CANCEL": "Annuler",
        "BUTTON_MODIFY": "Modifier ",
        "BUTTON_ADD": "Nouvelle modification",
        "BUTTON_IMPORT": "Importer une prévision",
        "BUTTON_COCKPIT": "Vers l'aperçu",
      },
      "STEP2": {
        "METHOD_ONLINE": "En ligne",
        "METHOD_UPLOAD": "Importer la prévision",
        "METHOD_RE_UPLOAD": "Importer la prévision à nouveau",
      },
      "STEP3": {
        "EDIT": "Modifier",
        "DELETE": "Effacer",
        "TIMERANGE": "Définir l'intervalle de temps",
        "TRANSFORMATION_TITLE": "Transformation de la prévision",
        "TRANSFORMATION_VALUE": "Valeur",
        "TRANSFORMATION_ERROR": "Erreur lors du calcul de la prévision. Les transformations antérieures ont été supprimées.",
        "TRANSFORMATIONS": {
          "ABSOLUTE": "Valeur de majoration/réduction",
          "PERCENTAGE": "Pourcentage de majoration/réduction",
          "CONSTANT": "Valeur fixe",
        },
      },
      "STEP4": {
        "TITLE": "Récapitulatif",
        "CONFIRMED": "Validée par BKW",
      },
      "LINK_BACK": "« Retour à l'aperçu",
      "FORECAST_MODIFICATION": {
        "IMPORT": "Prévision importée",
      },
      "TRANSFORMATION": {
        "ABSOLUTE": "Majoration/Réduction",
        "PERCENTAGE": "Majoration/Réduction",
        "CONSTANT": "Valeur fixe",
      },
      "CANCEL_DIALOG": {
        "TITLE": "Voulez-vous vraiment supprimer les données de prévision?",
        "ABORT": "Non, annuler",
        "CONFIRM": "Supprimer",
      },
      "IMPORT_DIALOG": {
        "TITLE": "Importer une prévision",
        "TIMERANGE": "Intervalle de la prévision",
        "TABLE_TIMESTAMP": "Horodatage (début de l'intervalle)",
        "TABLE_VALUE": "Valeur prévisionnelle [kW]",
        "ABORT": "Annuler",
        "IMPORT": "Importer",
        "TSNAME": "Prévision importée",
        "INFO_GRAPH": {
          "LINE1": "Valeur prévisionnelle",
          "LINE2": "Ajouter avec",
          "LINE3": "Copier-Coller",
        },
        "ERRORS": {
          "TOO_SHORT": "Les valeurs importées sont trop peu nombreuses pour l'horodatage spécifié. Il manque {{difference}} valeur(s).",
          "TOO_LONG": "Les valeurs importées sont trop nombreuses pour l'horodatage spécifié. Il y a {{difference}} valeur(s) en excès.",
          "WRONG_VALUE": "Erreur lors de l'importation",
        },
        "SUCCESS": {
          "IMPORT": "Valeurs prévisionnelles ajoutées avec succès",
        },
      },
    },
    "ERROR": {
      "LOAD_FAILED": "Erreur lors du chargement des points de mesure",
      "LOAD_HISTORY_FAILED": "Erreur lors du chargement des points de mesure",
      "GRAPH_LOAD_FAILED": "Erreur lors de la récupération des données du graphique",
      "GATE_CLOSED": "La fenêtre prévisionnelle est fermée et l'intervalle de prévision est réinitialisé.",
      "FORECAST_ERROR": "Erreur lors de la transmission de la prévision. Assurez-vous que les données sont conformes et essayez à nouveau.",
      "FORECAST_REJECTED": "Erreur lors de l'enregistrement de la prévision",
    },
  },
  "UNIT": {
    "KW": "kW",
    "KWH": "kWh",
    "PERCENTAGE": "%",
  },
  "UNAUTHORIZED": {
    "TITLE": "Accès refusé",
    "DESCRIPTION": "Il vous est malheureusement impossible d'accéder à ce service",
  },
  "ERRORS": {
    "NETWORK": "Nos systèmes informatiques sont dans l'incapacité momentanée de traiter toutes ou une partie des données (n° d'erreur {{code}}). Nous œuvrons à rétablir les conditions d'exploitation. Veuillez recharger la page. Service clients : 0844 121 120.",
    "GENERAL": "Erreur générale. Veuillez recharger la page. Vous pouvez nous contacter au moyen du formulaire de contact. Service clients : 0844 121 120.",
    "BUSINESS": {
      "LBENDTIMEBEFORESTARTTIME": "Erreur lors de la transmission de la prévision. Assurez-vous que la date de début est antérieure à la date de fin.",
      "LBSTARTTIMEOUTSIDEOFVALIDDAYS": "Il n'est pas possible de soumettre une prévision pour cet intervalle de temps.",
      "LBCOMPANYFOREIGNIDISNOTVALID": "Identifiant d'entreprise erroné. Merci de réinitialiser l'application.",
      "LBMETERINGPOINTDIDNOTMATCHCOMPANY": "Le point de meusre n'est pas lié à l'entreprise sélectionnée. Merci de réinitialiser l'application.",
      "LBBKWFORECASTGRAPHNOTFOUND": "Aucune prévision tierce de BKW n'a été trouvée.",
      "LBONEORMORETRANSFORMATIONOUTSIDERANGE": "Les modifications doivent porter sur l'intervalle de temps de la prévision.",
    },
  },
  "TIME_SERIES_TYPES": {
    "2": "Prévision tierce BKW",
    "7": "Prévision importée",
  },
  "BACK_BUTTON": "Retour à l'espace clients",
};