import { IBfcKeycloakConfig } from "@bfl/components/authentication";
import { IBfcConfiguration } from "@bfl/components/configuration";
import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { P_ENV } from "./environments/p";
import { Q_ENV } from "./environments/q";
import { T_ENV } from "./environments/t";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface DaveAppConfiguration {
  keycloakConfig?: IBfcKeycloakConfig;
  forecastApiUrl?: string;
  googleTagManagerContainerId?: string;
  globalContactURL?: string;
  daveAppLocal?: string;
  opSelfServiceApiUrl?: string;
  formatDateShort?: string;
  tablePageSize?: number;
  tablePageSizeOptions?: number[];
  opb2bDashboardUrl?: string;
}
