import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError, timer } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

/** MAX retries and recursion condition */
const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 500;

@Injectable()
export class ThwartThrottlingInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestRetryFactory: (retryNr: number) => Observable<HttpEvent<any>> = (retryNr) => {
      return next.handle(request).pipe(
        catchError((error: unknown) => {
          if (!(error instanceof HttpErrorResponse && error.status === 429) || retryNr >= MAX_RETRIES) {
            return throwError(error);
          } else {
            // trigger retry after INITIAL_RETRY_DELAY * retryNr (ms)
            return timer(++retryNr * INITIAL_RETRY_DELAY).pipe(
              switchMap(() => requestRetryFactory(retryNr)),
            );
          }
        }),
      );
    };

    return requestRetryFactory(0);
  }
}