import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";
import { Time } from "../model/time.interface";

@Injectable()
export class TimeService {

  private readonly minutesInQuarterHour: number = 15;

  private readonly userTimezone = moment.tz.guess();

  windBackToQuarterHour(dateTime: Moment): Moment {
    if (dateTime?.isValid()) {
      const offset: number = dateTime.minute() % this.minutesInQuarterHour;
      dateTime = dateTime.subtract(offset, "minutes");
    }
    return dateTime;
  }

  parseTime(time: string): Time {
    const split: string[] = time?.split(":");
    const result = { hour: 0, minute: 0 };
    if (split?.length === 2) {
      const hour = Number(split[0]);
      const minute = Number(split[1]);
      if (!isNaN(hour)) {
        result.hour = hour;
      }
      if (!isNaN(minute)) {
        result.minute = minute;
      }
    }
    return result;
  }

  momentToTime(dateTime: Moment): Time {
    return dateTime?.isValid() ?
      { hour: dateTime.tz(this.userTimezone).hour(), minute: dateTime.tz(this.userTimezone).minute() } :
      null;
  }

  isSameTime(time1: Time, time2: Time): boolean {
    return time1?.hour == time2?.hour && time1.minute == time2.minute;
  }

  isSameDate(date1: Moment, date2: Moment): boolean {
    if (!date1 || !date2){
      return false;
    }
    return date1.day() === date2.day() &&
            date1.month() === date2.month() &&
            date1.year() === date2.year();
  }

  toTimeString(time: Time): string {
    if (time){
      return String(time.hour).padStart(2, "0") + ":" + String(time.minute).padStart(2, "0");
    } else {
      return "";
    }
  }

  applyTime(dateTime: Moment, time: Time): Moment {
    return dateTime?.hour(time.hour)?.minute(time.minute);
  }

  isQuarterHour(time: Time): boolean {
    return time?.minute % 15 === 0;
  }
}
