import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";
import * as moment from "moment";

@Injectable()
export class InitializerProvider {
  constructor(
    private bfcTranslateService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService,
  ) {}

  public initialize() {
    moment.locale(this.bfcTranslateService.language);

    this.bfcGoogleAnalyticsService
      .initGoogleTagManager(this.bfcConfigurationService.configuration.googleTagManagerContainerId);

    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    const serviceItemsModify = JSON.stringify([
      {
        key: "contact",
        titleTranslatable: this.bfcTranslationService.translate("SERVICE_NAV.CONTACT"),
        url: this.bfcConfigurationService.configuration.globalContactURL,
      },
    ]);

    const serviceCustomItems = JSON.stringify([
      {
        key: "feedback",
        titleTranslatable: this.bfcTranslationService.translate("SERVICE_NAV.FEEDBACK"),
      },
    ]);

    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);
      globalNavElements[0].setAttribute("service-custom-items", serviceCustomItems);
    } else {
      throw (new Error("too many or no global navigation element found"));
    }

    const footerElements = document.getElementsByTagName("bfe-footer");
    if (footerElements.length === 1) {
      footerElements[0].setAttribute("contact-url", JSON.stringify(this.bfcConfigurationService.configuration.globalContactURL));
      footerElements[0].setAttribute("customer-segment", "B2B");
    } else {
      throw (new Error("too many or no bfe-footer element found"));
    }
  }
}
