import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { translations } from "./config/translations/translations";
import { AuthGuardForecast } from "./core/service/auth-guard-forecast.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "cockpit",
    pathMatch: "full",
  },
  {
    path: "cockpit",
    loadChildren: () => import("./forecast/cockpit/cockpit.module").then((m) => m.CockpitModule),
    canActivate: [AuthGuardForecast],
  },
  {
    path: "history",
    loadChildren: () => import("./forecast/history/history.module").then((m) => m.HistoryModule),
    canActivate: [AuthGuardForecast],
  },
  {
    path: "manage",
    loadChildren: () => import("./forecast/manage/manage.module").then((m) => m.ManageModule),
    canActivate: [AuthGuardForecast],
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "error",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
