import { Injectable } from "@angular/core";
import { TimeRange } from "dave-common";
import * as moment from "moment-timezone";
import { Moment } from "moment";
import { TimeService } from "src/app/core/service/time.service";
import { TimeRangeEnum } from "../../forecast/manage/model/timerange.enum";

@Injectable()
export class TimeRangeService {

  private readonly userTimezone = moment.tz.guess();

  constructor(private timeService: TimeService) {}

  getTimeRange(timeRange: TimeRangeEnum): TimeRange {
    const now = moment().tz(this.userTimezone);
    const todayStart: Moment = moment().tz(this.userTimezone).startOf("day");
    const todayEnd: Moment = moment().tz(this.userTimezone).endOf("day");
    let startDateTime: Moment = undefined;
    let endDateTime: Moment = undefined;
    switch (timeRange) {
      case TimeRangeEnum.YESTERDAY:
        startDateTime = todayStart.subtract(1, "days");
        endDateTime = todayEnd.subtract(1, "days");
        break;
      case TimeRangeEnum.CURRENT_WEEK:
        startDateTime = todayStart.startOf("isoWeek");
        endDateTime = now;
        break;
      case TimeRangeEnum.LAST_WEEK:
        startDateTime = todayStart.subtract(1, "weeks").startOf("isoWeek");
        endDateTime = todayEnd.subtract(1, "weeks").endOf("isoWeek");
        break;
      case TimeRangeEnum.CURRENT_MONTH:
        startDateTime = todayStart.startOf("month");
        endDateTime = now;
        break;
      case TimeRangeEnum.LAST_MONTH:
        startDateTime = todayStart.subtract(1, "months").startOf("month");
        endDateTime = todayEnd.subtract(1, "months").endOf("month");
        break;
      case TimeRangeEnum.CURRENT_QUARTER:
        startDateTime = todayStart.startOf("quarter");
        endDateTime = now;
        break;
      case TimeRangeEnum.LAST_QUARTER:
        startDateTime = todayStart.subtract(1, "quarter").startOf("quarter");
        endDateTime = todayEnd.subtract(1, "quarter").endOf("quarter");
        break;
      case TimeRangeEnum.CURRENT_YEAR:
        startDateTime = todayStart.startOf("year");
        endDateTime = now;
        break;
      case TimeRangeEnum.LAST_YEAR:
        startDateTime = todayStart.subtract(1, "years").startOf("year");
        endDateTime = todayEnd.subtract(1, "years").endOf("year");
        break;
    }

    switch (timeRange) {
      case TimeRangeEnum.LAST_QUARTER:
      case TimeRangeEnum.LAST_YEAR:
      case TimeRangeEnum.LAST_MONTH:
      case TimeRangeEnum.LAST_WEEK:
      case TimeRangeEnum.YESTERDAY:
        // setting proper start and end times including all values for the days
        startDateTime = startDateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        endDateTime = endDateTime.set({ hour: 23, minute: 45, second: 0, millisecond: 0 });
        break;
      case TimeRangeEnum.CURRENT_YEAR:
      case TimeRangeEnum.CURRENT_QUARTER:
      case TimeRangeEnum.CURRENT_MONTH:
      case TimeRangeEnum.CURRENT_WEEK:
        // wind back now to the last full quarter hour
        endDateTime = this.timeService.windBackToQuarterHour(endDateTime);
        break;
    }

    // default is by design {undefined, undefined}
    return { startDateTime, endDateTime };
  }
}