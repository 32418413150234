export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Prognose Energie",
  "FORECAST": {
    "COCKPIT": {
      "TITLE": "Prognose",
      "LINK_HISTORY": "Historie der Prognose",
      "TABLE": {
        "METERPOINT": "Messpunkt",
        "TYPE": "Typ",
        "LAST_UPDATE": "Letzte Einreichung",
        "GATECLOSURE": "Einreichezeitpunkt",
        "GATECLOSURE_FOR": "Einreichen für *",
        "HINT": "* Prognose sollte bis zum nächsten Einreichungszeitpunkt für die definierten Tage eingereicht werden.",
      },
    },
    "HISTORY": {
      "LINK_BACK": "« zurück zur Übersicht",
      "TITLE": "Historie der Prognose",
      "TABLE": {
        "DATE": "Zeitstempel",
        "BY": "Ausgeführt von",
        "NAME": "Messpunktname",
        "CODE": "Messpunktnummer",
      },
    },
    "MANAGE": {
      "TITLE": "Prognose",
      "METERPOINT_SELECTION": "Messstelle auswählen ",
      "LOAD_FULLRANGE": "Komplette Zeitreihe laden",
      "WHICH_FORECAST": "Auf welche Prognose?",
      "TIMERANGE_TITLE": "Zeitraum eingeben",
      "TIMERANGE_START": "Von (inklusive)",
      "TIMERANGE_END": "Bis (exklusive)",
      "TIMERANGE_ERROR": "Endzeit muss nach Startzeit sein",
      "EDIT_PROVIDED": "BKW Prognose online bearbeiten",
      "EDIT_IMPORTED": "Importierte Prognose online bearbeiten",
      "STEPPER": {
        "TITLE": "Prognose ändern",
        "BUTTON_CHANGE": "Ändern",
        "BUTTON_CONFIRM": "Jetzt bestätigen",
        "BUTTON_NEXT": "Weiter",
        "BUTTON_PREVIOUS": "Zurück",
        "BUTTON_SUBMIT": "Abschliessen",
        "BUTTON_CANCEL": "Abbrechen",
        "BUTTON_MODIFY": "Jetzt modifizieren",
        "BUTTON_ADD": "Neue Anpassung",
        "BUTTON_IMPORT": "Prognose importieren",
        "BUTTON_COCKPIT": "Zur Übersicht",
      },
      "STEP2": {
        "METHOD_ONLINE": "Online",
        "METHOD_UPLOAD": "Prognose importieren",
        "METHOD_RE_UPLOAD": "Prognose erneut importieren",
      },
      "STEP3": {
        "EDIT": "Ändern",
        "DELETE": "Löschen",
        "TIMERANGE": "Zeitraum eingeben",
        "TRANSFORMATION_TITLE": "Transformation Prognose",
        "TRANSFORMATION_VALUE": "Wert",
        "TRANSFORMATION_ERROR": "Fehler beim Berechnen der Prognose. Bisherige Transformationen wurden entfernt.",
        "TRANSFORMATIONS": {
          "ABSOLUTE": "Zu/Abschlag Absolut",
          "PERCENTAGE": "Zu/Abschlag Prozentual",
          "CONSTANT": "Einheitswert",
        },
      },
      "STEP4": {
        "TITLE": "Zusammenfassung",
        "CONFIRMED": "Bestätigt durch BKW",
      },
      "LINK_BACK": "« zurück zur Übersicht",
      "FORECAST_MODIFICATION": {
        "IMPORT": "Importierte Prognose",
      },
      "TRANSFORMATION": {
        "ABSOLUTE": "Zu/Abschlag",
        "PERCENTAGE": "Zu/Abschlag",
        "CONSTANT": "Einheitswert",
      },
      "CANCEL_DIALOG": {
        "TITLE": "Möchten Sie die eingegebenen Prognosedaten wirklich verwerfen?",
        "ABORT": "Nein, abbrechen!",
        "CONFIRM": "Verwerfen",
      },
      "IMPORT_DIALOG": {
        "TITLE": "Prognose importieren",
        "TIMERANGE": "Prognosezeitraum: ",
        "TABLE_TIMESTAMP": "Zeitstempel (Von-Zeit)",
        "TABLE_VALUE": "Prognosewert [kW]",
        "ABORT": "Abbrechen",
        "IMPORT": "Jetzt importieren",
        "TSNAME": "Importierte Prognose",
        "INFO_GRAPH": {
          "LINE1": "Prognosewerte",
          "LINE2": "einfügen mit",
          "LINE3": "Copy + Paste",
        },
        "ERRORS": {
          "TOO_SHORT": "Ihre eingefügten Prognosewerte sind {{difference}} Werte zu kurz für den vorgegebenen Zeitstempel.",
          "TOO_LONG": "Ihre eingefügten Prognosewerte sind {{difference}} Werte zu lang für den vorgegebenen Zeitstempel.",
          "WRONG_VALUE": "Beim Import ist ein Fehler aufgetreten:",
        },
        "SUCCESS": {
          "IMPORT": "Prognosewerte erfolgreich eingefügt",
        },
      },
    },
    "ERROR": {
      "LOAD_FAILED": "Messpunkte konnten nicht geladen werden.",
      "LOAD_HISTORY_FAILED": "Messpunkte konnten nicht geladen werden.",
      "GRAPH_LOAD_FAILED": "Daten für die Darstellung vom Graph konnten nicht geladen werden.",
      "GATE_CLOSED": "Gate ist geschlossen, deshalb wird der Prognose Zeitraum zurückgesetzt.",
      "FORECAST_ERROR": "Prognose konnte nicht übermittelt werden. Überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
      "FORECAST_REJECTED": "Prognose konnte nicht erfolgreich gespeichert werden.",
    },
  },
  "UNIT": {
    "KW": "kW",
    "KWH": "kWh",
    "PERCENTAGE": "%",
  },
  "UNAUTHORIZED": {
    "TITLE": "Kein Zugriff",
    "DESCRIPTION": "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  "ERRORS": {
    "NETWORK": "Aktuell können wir keine oder nicht alle Daten von unseren Systemen empfangen. Wir arbeiten daran den Fehler zu beheben (Nr. {{code}}). Bitte versuchen Sie die Seite neuzuladen. Kundenservice: 0844 121 120.",
    "GENERAL": "Es ist ein allgemeiner Fehler aufgetreten. Bitte versuchen Sie die Seite neuzuladen. Sie können uns via Kontakt-Formular benachrichtigen. Kundenservice: 0844 121 120.",
    "BUSINESS": {
      "LBENDTIMEBEFORESTARTTIME": "Prognose konnte nicht übermittelt werden. Bitte stellen Sie sicher, dass das Enddatum nach dem Startdatum ist.",
      "LBSTARTTIMEOUTSIDEOFVALIDDAYS": "Für diesen Zeitraum kann keine Prognose übermittelt werden.",
      "LBCOMPANYFOREIGNIDISNOTVALID": "Firmen ID stimmt nicht. Bitte starten Sie die Applikation neu.",
      "LBMETERINGPOINTDIDNOTMATCHCOMPANY": "Messpunkt gehört nicht zu ausgewählten Firma. Bitte starten Sie die Applikation neu.",
      "LBBKWFORECASTGRAPHNOTFOUND": "Keine BKW Fremdprognose gefunden.",
      "LBONEORMORETRANSFORMATIONOUTSIDERANGE": "Modifikation muss innerhalb des Zeitraum von der Prognose liegen.",
    },
  },
  "TIME_SERIES_TYPES": {
    "2": "BKW Fremdprognose",
    "7": "Importierte Prognose",
  },
  "BACK_BUTTON": "zurück zum Kundencenter",
};