import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { ROLE_FORECAST } from "../../config/app-security-roles.config";

@Injectable()
export class AuthGuardForecast implements CanActivate {
  constructor(private bfcAuthenticationService: BfcAuthenticationService, private router: Router) {}

  canActivate() {
    const hasRole = this.bfcAuthenticationService.hasRealmRole(ROLE_FORECAST);
    if (!hasRole) {
      this.router.navigate(["/unauthorized"], { skipLocationChange: true });
    } else {
      return true;
    }
  }
}
