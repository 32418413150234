import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import {
  MeteringPointGraph,
  TimeSeriesCollection,
  TimeSeriesType,
  TimeSeriesUtilityService,
} from "dave-common";
import * as moment from "moment";
import { forkJoin, ReplaySubject, Subject } from "rxjs";
import { Observable } from "rxjs";
import { map, takeUntil, timeout } from "rxjs/operators";
import { Forecast } from "../model/forecast.interface";
import { GateClosure } from "../model/gate-closure.interface";
import { MeterPoint } from "../model/meterpoint.interface";
import { TransformationType } from "../model/transformation-type.enum";
import { ValidDay } from "../model/valid-day.interface";
import { ROLE_FORECAST } from "../../config/app-security-roles.config";
import { OrganizationService } from "./organisation.service";

const METERING_POINT_ENTRYPOINT = "/meteringpoints/";
const METERING_POINT_GRAPH_ENTRYPOINT = "/meteringpointGraphs/";
const FORECAST_VALID_DAYS_ENTRYPOINT = "/forecastvaliddays/";
const GATE_CLOSURES_ENTRYPOINT = "/gateclosures/";
const FORECASTS_ENTRYPOINT = "/forecasts/";

@Injectable()
export class ForecastService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  meterPoints$: ReplaySubject<MeterPoint[]> = new ReplaySubject(1);

  constructor(private httpService: HttpClient,
    private configService: BfcConfigurationService,
    private organizationService: OrganizationService,
    private bfcTranslationService: BfcTranslationService,
    private timeSeriesUtilityService: TimeSeriesUtilityService,
    private notificationService: BfcNotificationService) {
  }

  ngOnDestroy(): void {
    this.meterPoints$.next(null);
    this.meterPoints$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadMeteringPoints(commonOrganisationId?: number) {
    if (commonOrganisationId) {
      this.getMeteringPoints(commonOrganisationId);
    } else {
      this.organizationService.getCurrentOrganisation(ROLE_FORECAST).pipe(takeUntil(this.unsubscribe))
        .subscribe(organization => {
          if (organization) {
            this.getMeteringPoints(organization.commonOrganisationId);
          } else {
            // user has no organization ==> maybe a permission issue? However return empty list.
            this.meterPoints$.next([]);
          }
        });
    }
  }

  private getMeteringPoints(companyForeignId: number) {
    const url = this.configService.configuration.forecastApiUrl + METERING_POINT_ENTRYPOINT;
    const params = new HttpParams().set("companyForeignId", String(companyForeignId));
    this.httpService.get<MeterPoint[]>(url, { params }).pipe(
      timeout(60000),
      takeUntil(this.unsubscribe),
    ).subscribe(data => {
      this.meterPoints$.next(data);
    },
    () => {
      this.notificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("FORECAST.ERROR.LOAD_FAILED"),
      });
    },
    );
  }

  getGateClosure$(companyForeignId: number): Observable<GateClosure> {
    const params = new HttpParams().set("companyForeignId", String(companyForeignId));

    return forkJoin([
      // merge api calls into a usable object
      this.httpService
        .get(this.configService.configuration.forecastApiUrl + FORECAST_VALID_DAYS_ENTRYPOINT, { params }),
      this.httpService
        .get(this.configService.configuration.forecastApiUrl + GATE_CLOSURES_ENTRYPOINT, { params }),
    ]).pipe(
      map(([validDays, gateClosureDate]) => {
        const mergedResult: GateClosure = {
          gateClose: moment(gateClosureDate),
          validDays: validDays as ValidDay[],
        };
        return mergedResult;
      }),
    );
  }

  getMeteringPointGraphs$(
    companyForeignId: number,
    code: string,
    wholeRange = false,
  ): Observable<TimeSeriesCollection[]> {
    const url = this.configService.configuration.forecastApiUrl + METERING_POINT_GRAPH_ENTRYPOINT + code;
    const params = new HttpParams().set("companyForeignId", String(companyForeignId)).set("wholeRange", "" + wholeRange);
    return this.httpService.get<MeteringPointGraph[]>(url, { params }).pipe(
      timeout(60000),
      map((ts: MeteringPointGraph[]) => {
        let timeSeries: TimeSeriesCollection[] = [];
        // Modify fetched graphdata so that highcharts can render them
        ts.forEach((collection: MeteringPointGraph) => {
          timeSeries.push(this.timeSeriesUtilityService.mapGraphToCollection(collection));
        });
        return timeSeries;
      }));
  }

  calculateForecast(
    companyForeignId: number,
    meterCode: string,
    forecast: Forecast,
    tsType: TimeSeriesType,
  ): Observable<MeteringPointGraph> {
    const params = new HttpParams().set("companyForeignId", String(companyForeignId)).set("tsType", tsType.toString());
    return this.httpService.post<MeteringPointGraph>(
      this.configService.configuration.forecastApiUrl + FORECASTS_ENTRYPOINT + meterCode, forecast, { params })
      .pipe(
        timeout(60000),
      );
  }

  submitForecast(companyForeignId: number, meterCode: string, forecast: Forecast): Observable<Forecast> {
    const params = new HttpParams().set("companyForeignId", String(companyForeignId));
    return this.httpService.put<Forecast>(
      this.configService.configuration.forecastApiUrl + FORECASTS_ENTRYPOINT + meterCode, forecast, { params })
      .pipe(
        map(forecastResult => {
          forecastResult.forecastTransformations.forEach(transformation => {
            if (transformation.transformation) {
              // to uppercase ==> string enum
              //   eslint-disable-next-line max-len
              transformation.transformation = TransformationType[transformation.transformation.toString().toUpperCase()];
            }
          });
          return forecastResult;
        }),
      );
  }
}
