import { CommonModule } from "@angular/common";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { BfcAutocompleteModule } from "@bfl/components/autocomplete";
import { BfcTranslationModule } from "@bfl/components/translation";
import { OrganizationAppRole } from "./model/organization-app-role.interface";
import { OrganizationSelectorComponent } from "./organization-selector.component";
import { OrganizationSelectorService } from "../core/service/organization-selector.service";
import { BfcInputModule } from "@bfl/components/input";
import { BfcFormModule } from "@bfl/components/form";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { MatSelectModule } from "@angular/material/select";

export const OrganizationSelectorServiceConfig = new InjectionToken<OrganizationAppRole>("OrganizationSelectorServiceConfig");

@NgModule(
  {
    imports: [
      BfcTranslationModule,
      BfcAutocompleteModule,
      BfcInputModule,
      BfcFormModule,
      BfcFormFieldModule,
      CommonModule,
      MatSelectModule,
    ],
    declarations: [
      OrganizationSelectorComponent,
    ],
    exports: [
      OrganizationSelectorComponent,
    ],
  },
)
export class OrganizationModule {
  static forRoot(config: OrganizationAppRole): ModuleWithProviders<any> {
    return {
      ngModule: OrganizationModule,
      providers: [
        OrganizationSelectorService,
        {
          provide: OrganizationSelectorServiceConfig,
          useValue: config,
        },
      ],
    };
  }
}
