import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { BfcNotificationService } from "@bfl/components/notification";
import { TimeSeriesUtilityService } from "dave-common";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { AuthGuardForecast } from "./service/auth-guard-forecast.service";
import { CockpitService } from "./service/cockpitinfo.service";
import { ForecastService } from "./service/forecast.service";
import { HistoryService } from "./service/history.service";
import { OrganizationSelectorService } from "./service/organization-selector.service";
import { OrganizationService } from "./service/organisation.service";
import { TimeService } from "./service/time.service";
import { TimeRangeService } from "./service/timerange.service";

@NgModule({
  imports: [
    HttpClientModule,
  ],
  exports: [],
  declarations: [], 
  providers: [
    InitializerProvider,
    AuthGuardForecast,
    CockpitService,
    HistoryService,
    ForecastService,
    TimeSeriesUtilityService,
    BfcNotificationService,
    OrganizationService,
    OrganizationSelectorService,
    TimeService,
    TimeRangeService,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
