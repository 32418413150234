import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { OrganizationSelectorService } from "../core/service/organization-selector.service";
import { Subject } from "rxjs";
import { concatMap, takeUntil } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { Organisation } from "dave-common";

@Component({
  selector: "app-organization-selector",
  templateUrl: "organization-selector.component.html",
  styleUrls: ["organization-selector.component.scss"],
})
export class OrganizationSelectorComponent implements OnInit, OnDestroy {

  @ViewChild("organisationSelection") organisationSelection: any;

  private unsubscribe: Subject<void> = new Subject();

  private organisations: Organisation[] = [];

  organizationCtrl = new FormControl();

  filteredOrganisations: Organisation[] = [];

  constructor(private organizationSelectorService: OrganizationSelectorService) {}

  ngOnInit(): void {
    // get all organizations
    this.organizationSelectorService.getOrganizations()
      .pipe(
        concatMap(organisations => {
          this.organisations = organisations
          // remove orgs without id
            .filter(organisation => organisation && organisation.organisationId)
          // remove duplicates by org id (keep first)
            .filter((organisation, index, self) =>
              index === self.findIndex((org) => (
                org.organisationId === organisation.organisationId
              )),
            );
          // reset the filter -> show all
          this.filterOrganisations(null);

          // prefill first match
          return this.organizationSelectorService.getCurrentOrganisation();
        }),
        takeUntil(this.unsubscribe),
      ).subscribe(organisation => {
        this.filterOrganisations(organisation);
        this.organizationCtrl.patchValue(organisation);
      });

    // subscribe to input changes
    this.organizationCtrl.valueChanges.pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(name => this.filterOrganisations(name));
  }

  onSelect(org: Organisation) {
    this.organizationSelectorService.selectionChanged(org);
  }

  // display funciton for the autocomplete
  getDisplayFunction() {
    return (org: Organisation): string => org?.name ? org.name : "";
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // filter organizations by name
  private filterOrganisations(value: string | Organisation): void {
    let filtered: Organisation[];

    if (!!value) {
      if (typeof value === "object") {
        // filter by object id
        filtered = this.organisations.filter(org =>
          org &&
                    org.organisationId === value.organisationId,
        );
      } else {
        // filter by name
        filtered = this.organisations.filter(org =>
          org?.name &&
                    org.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
        );
      }
    } else {
      filtered = this.organisations.slice();
    }

    this.filteredOrganisations = filtered;
  }
}
