import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { forkJoin, of } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { Observable } from "rxjs";
import { Organisation } from "dave-common";
import { ServiceWithCommonOrganisationIds } from "../../organisation/model/service-with-common-organisation-ids.interface";

@Injectable()
export class OrganizationService {

  private readonly ROLE_FORECAST = "ROLE_DAVE_FORECAST";

  private serviceWithCommonOrganisationIds: Observable<ServiceWithCommonOrganisationIds[]>;

  private organisations: Observable<Organisation[]>;

  private organisation: Organisation;

  public organisationChanged: EventEmitter<Organisation> = new EventEmitter<Organisation>();

  constructor(
    private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
  ) {
    this.organisationChanged.subscribe(organisation => this.organisation = organisation);
  }

  getCurrentOrganisation(codeMustBe: string): Observable<Organisation> {
    if (
      this.organisation &&
            (this.organisation as any)?.codes?.indexOf(codeMustBe) !== -1
    ) {
      // returing present selected organisation
      return of(this.organisation);
    } else {
      return this.getDaveOrganisations([codeMustBe]).pipe(
        // returning first as focused organisation
        map((organisations: Organisation[]): Organisation => organisations[0]),
      );
    }
  }

  setCurrentOrganisation(commonOrganisationId: number): void {
    this.getDaveOrganisations().subscribe(organisations => {
      this.organisation = organisations.find(
        organisation => organisation.commonOrganisationId === commonOrganisationId,
      );
    });
  }

  getDaveOrganisations(
    serviceCodes: string[] = [this.ROLE_FORECAST],
  ): Observable<Organisation[]> {
    return forkJoin([
      // getting all organisation and not just the ones mentioned in ServiceWithCommonOrganisationIds
      // in 99% of all cases more efficient than requesting one by one
      this.getOrganisations(),
      // use the search api to get all organisationIds relevant for dave apps
      this.getServiceWithCommonOrganisationIds(),
    ]).pipe(
      map((data): Organisation[] => {
        const organisations: Organisation[] = data[0];
        let mappings: ServiceWithCommonOrganisationIds[] = data[1] ? data[1] : [];

        // remove mappings not wanted by $withDaveRoles
        mappings = mappings.filter(mapping => serviceCodes.indexOf(mapping?.service?.code) !== -1);

        return organisations
        // remove all organisation in a mapping
          .filter(organisation =>
            mappings.some(mapping =>
              mapping.commonOrganisationIds.some(commonId => commonId === organisation.commonOrganisationId),
            ),
          ).map((organisation: Organisation): Organisation => {
            // force codes onto the organisations for later filtering
            (organisation as any).codes = mappings
            // get mappings relevant for this organisation
              .filter(mapping => mapping.commonOrganisationIds.some(id => id === organisation.commonOrganisationId))
            // trim down to only the service code
              .map((mapping): string => mapping?.service?.code)
            // clean
              .filter(code => code !== null && code !== undefined)
            // make code array unique
              .reduce((codes: string[], code: string) => {
                return codes.indexOf(code) !== -1 ? codes : [... codes, code];
              }, []);

            return organisation;
          });
      }),
      map(organisations => this.sortOrganisations(organisations)),
    );
  }

  sortOrganisations(organisations: Organisation[]): Organisation[] {
    return organisations.sort((a, b) => {
      if (a?.name > b?.name) return 1;
      if (a?.name < b?.name) return -1;
      return 0;
    });
  }

  getOrganisations(): Observable<Organisation[]> {
    if (!this.organisations) {
      this.organisations = this.requestOrganisations().pipe(
        catchError(() => of([])), // also caching a failed requests
        shareReplay({
          refCount: false,
          bufferSize: 1,
        }),
      );
    }
    return this.organisations;
  }

  /**
     * Requesting all organisations with ?filterByMasterSystem=MSDYNAMICS"
     */
  private requestOrganisations(): Observable<Organisation[]> {
    return this.httpClient.get<any>(
      this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/organisations?filterByMasterSystem=MSDYNAMICS",
    ).pipe(
      map((resources: any): Organisation[] => {
        return resources._embedded ? resources._embedded.organisations : [];
      }),
    );
  }

  getServiceWithCommonOrganisationIds(): Observable<ServiceWithCommonOrganisationIds[]> {
    if (!this.serviceWithCommonOrganisationIds) {
      this.serviceWithCommonOrganisationIds = this.requestServiceWithCommonOrganisationIds().pipe(
        catchError(() => of([])), // also caching a failed requests
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    }
    return this.serviceWithCommonOrganisationIds;
  }

  private requestServiceWithCommonOrganisationIds(): Observable<ServiceWithCommonOrganisationIds[]> {
    const params: HttpParams = new HttpParams()
      .set("codes", this.ROLE_FORECAST);

    return this.httpClient.get<any>(
      this.bfcConfigurationService.configuration.opSelfServiceApiUrl + "/me/services/all/search",
      { params },
    ).pipe(
      map((resources): ServiceWithCommonOrganisationIds[] =>
        resources?._embedded?.servicesWithCommonOrganisationIds
          ? resources._embedded.servicesWithCommonOrganisationIds
          : [],
      ),
    );
  }
}
