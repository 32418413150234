import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BfcTranslationService } from "@bfl/components/translation";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  constructor(private bfcTranslationService: BfcTranslationService, private titleService: Title) {
    this.titleService.setTitle(this.bfcTranslationService.translate("SERVICE_NAME"));
  }
}
