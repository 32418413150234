import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { Organisation } from "dave-common";
import { ReplaySubject, Subject } from "rxjs";
import { map, takeUntil, timeout } from "rxjs/operators";
import { CockpitInfo } from "../model/cockpitinfo.interface";
import { ForecastProposalType } from "../model/forecast-proposal-type.enum";
import { ROLE_FORECAST } from "../../config/app-security-roles.config";
import { OrganizationService } from "./organisation.service";

const COCKPIT_INFO_ENTRYPOINT = "/cockpitInfo/";

@Injectable()
export class CockpitService implements OnDestroy {

  private unsubscribe: Subject<void> = new Subject();

  cockpitInformation$: ReplaySubject<CockpitInfo[]> = new ReplaySubject(1);

  constructor(private httpService: HttpClient,
    private configService: BfcConfigurationService,
    private organizationService: OrganizationService,
    private bfcTranslationService: BfcTranslationService,
    private notificationService: BfcNotificationService) {
  }

  ngOnDestroy(): void {
    this.cockpitInformation$.next(null);
    this.cockpitInformation$.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  loadCockpitInformation(commonOrganisationId?: number) {
    if (commonOrganisationId) {
      this.getCockpitInformation(commonOrganisationId);
    } else {
      this.organizationService.getCurrentOrganisation(ROLE_FORECAST)
        .pipe(takeUntil(this.unsubscribe)).subscribe((organisation: Organisation) => {
          if (organisation) {
            this.getCockpitInformation(organisation.commonOrganisationId);
          }
        });
    }
  }

  getCockpitInformation(companyForeignId: number) {
    const url = this.configService.configuration.forecastApiUrl + COCKPIT_INFO_ENTRYPOINT;
    const params = new HttpParams().set("companyForeignId", String(companyForeignId));

    this.httpService.get<CockpitInfo[]>(url, { params }).pipe(
      timeout(60000),
      map(cockpitInfos => {
        cockpitInfos.forEach(cockpitInfo => {
          cockpitInfo.forecastProposalType =
            cockpitInfo.containsForecastProposal ? ForecastProposalType.BKW : ForecastProposalType.BELVIS;
        });
        return cockpitInfos;
      }),
      takeUntil(this.unsubscribe),
    ).subscribe(data => {
      this.cockpitInformation$.next(data);
    },
    () => {
      this.notificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("FORECAST.ERROR.LOAD_FAILED"),
      });
    },
    );
  }
}
