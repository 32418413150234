import { Inject, Injectable, Optional } from "@angular/core";
import { Observable } from "rxjs";
import { OrganizationAppRole } from "../../organisation/model/organization-app-role.interface";
import { OrganizationSelectorServiceConfig } from "../../organisation/organization.module";
import { OrganizationService } from "./organisation.service";
import { Organisation } from "dave-common";

@Injectable()
export class OrganizationSelectorService {

  private readonly filterRole: string;

  constructor(
    private organisationService: OrganizationService,
    @Inject(OrganizationSelectorServiceConfig) @Optional() config: OrganizationAppRole,
  ) {
    if (config.roleName) {
      this.filterRole = config.roleName;
    } else {
      // needs the roleName in forRoot config in your module!
      // eg: OrganizationModule.forRoot({roleName: ROLE_VISUALIZATION})
      // eslint-disable-next-line no-console
      console.error("OrganizationSelectorService is missing the filter role! Check your module configuration!");
    }
  }

  public getCurrentOrganisation(): Observable<Organisation> {
    return this.organisationService.getCurrentOrganisation(this.filterRole);
  }

  public getOrganizations(): Observable<Organisation[]> {
    return this.organisationService.getDaveOrganisations([this.filterRole]);
  }

  public selectionChanged(organization: Organisation): void {
    // emit the new selected organization
    this.organisationService.organisationChanged.emit(organization);
  }
}
