import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { OverlayModule } from "@angular/cdk/overlay";
import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import localeDECH from "@angular/common/locales/de-CH";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
} from "@bfl/components/authentication";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { AppComponent } from "./app.component";
import {  AppRoutingModule } from "./app-routing.module";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { DaveErrorHandler } from "./core/error/dave-error-handler.service";
import { ThwartThrottlingInterceptor } from "./core/error/thwart-throttling.interceptor";
import { OrganizationModule } from "./organisation/organization.module";

registerLocaleData(localeDECH);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    BfcTranslationModule,
    AppRoutingModule,
    BfcGoogleAnalyticsModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    CoreModule,
    OverlayModule,
    BfcSinglePageLayoutModule,
    BfcTranslationModule,
    FlexLayoutModule,
    OrganizationModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ThwartThrottlingInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: DaveErrorHandler,
    },
    {
      provide: LOCALE_ID,
      useValue: "de-CH",
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
