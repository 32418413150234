import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";
import { BackendError } from "./backend-error.interface";

@Injectable()
export class DaveErrorHandler implements ErrorHandler {

  constructor(private bfcTranslationService: BfcTranslationService,
    private notificationService: BfcNotificationService) {
  }

  handleError(error: Error | HttpErrorResponse) {

    if (error instanceof HttpErrorResponse) {
      if (this.isBusinessError(error)) {
        const defaultErrorMsg = this.bfcTranslationService.translate("FORECAST.ERROR.FORECAST_ERROR");
        let errorText = "";

        error.error.forEach((backendError: BackendError) => {
          const errorMessageKey = "ERRORS.BUSINESS." + backendError.errorMessageResourceKey.toUpperCase();
          errorText = this.bfcTranslationService.translate(errorMessageKey);
          if (errorText === errorMessageKey) {
            errorText = backendError.errorMessageResourceKey;
          }

          this.notificationService.showNotification({
            type: BfcNotificationType.WARNING,
            message: errorText ? errorText : defaultErrorMsg,
          });
        });
      } else {
        const code = error.status ? "" + error.status : "01";
        this.notificationService.showNotification({
          type: BfcNotificationType.ERROR,
          message: this.bfcTranslationService.translate("ERRORS.NETWORK", { "code": code }),
        });
      }
    } else {
      this.notificationService.showNotification({
        type: BfcNotificationType.ERROR,
        message: this.bfcTranslationService.translate("ERRORS.GENERAL"),
      });

    }

    // Don't swallow the error
    // eslint-disable-next-line no-console
    console.error(error);
  }

  private isBusinessError(error: HttpErrorResponse): boolean {
    return error.error &&
      error.error.length > 0 &&
      (error.error[0].errorType || error.error[0].errorMessageResourceKey) &&
      navigator.onLine;
  }
}