<mat-form-field>
    <mat-label>{{'ORGANIZATION_COMPONENT.SELECT_ORGANISATION' | bfcTranslate}}</mat-label>
    <input type="text"
           matInput
           [formControl]="organizationCtrl"
           #organisationSelection
           [matAutocomplete]="organisationAutocomplete">

    <mat-autocomplete [displayWith]="getDisplayFunction()"
                      (optionSelected)="onSelect($event.option.value)"
                      #organisationAutocomplete="matAutocomplete">

        <mat-option *ngFor="let organisation of filteredOrganisations"
                    [value]="organisation">
            {{organisation?.name}}
        </mat-option>

    </mat-autocomplete>

</mat-form-field>
